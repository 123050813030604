import { useState } from 'react'
import html2canvas from 'html2canvas'

/**
 * @module Main_Hook
 * Hook return
 * @typedef {Array} HookReturn
 * @property {string} HookReturn[0] - image string
 * @property {string} HookReturn[1] - take screen shot string
 * @property {object} HookReturn[2] - errors
 */

// Uncomment to allow screenshot generation through proxy, which is hosted by annotate-widget-backend
// const proxyUrl = `${process.env.REACT_APP_WIDGET_BACKEND_URL}/html2canvas-proxy`;

/**
 * hook for creating screenshot from html node
 * @returns {HookReturn}
 */
const useScreenshot = ({ type, quality } = {}) => {
  const [image, setImage] = useState(null)
  const [error, setError] = useState(null)
  /**
   * convert html node to image
   * @param {HTMLElement} node
   */
  const takeScreenShot = (node) => {
    if (!node) {
      throw new Error('You should provide correct html node.')
    }

    const options = {
      // Start capture from current scroll position
      allowTaint: true,
      y: (window.scrollY < 0) ? (window.scrollY * -1) : (window.scrollY),
      x: (window.scrollX < 0) ? (window.scrollX * -1) : (window.scrollX),
        scrollX: 0,
        scrollY: 0,
        width: window.innerWidth || document.clientWidth || document.getElementsByTagName('body')[0].clientWidth,
        height: window.innerHeight || document.clientHeight || document.getElementsByTagName('body')[0].clientHeight,
      // proxy: proxyUrl,
      // allowTaint: true,
      // logging: true
      useCORS: true,
    };

    return html2canvas(node, options)
      .then((canvas) => {
        const croppedCanvas = document.createElement('canvas')
        const croppedCanvasContext = croppedCanvas.getContext('2d')
        // init data
        const cropPositionTop = 0
        const cropPositionLeft = 0
        const cropWidth = canvas.width
        const cropHeight = canvas.height; // Setting this to window.height for demoing. Originally canvas.height. Will need cross-browser testing.

        croppedCanvas.width = cropWidth
        croppedCanvas.height = cropHeight

        croppedCanvasContext.drawImage(
          canvas,
          cropPositionLeft,
          cropPositionTop,
        )

        const base64Image = croppedCanvas.toDataURL(type, quality)

        setImage(base64Image)
        return base64Image
      })
      .catch(setError)
  }

  return [
    image,
    takeScreenShot,
    {
      error,
    },
  ]
}

/**
 * creates name of file
 * @param {string} extension
 * @param  {string[]} parts of file name
 */
const createFileName = (extension = '', ...names) => {
  if (!extension) {
    return ''
  }

  return `${names.join('')}.${extension}`
}

export { useScreenshot, createFileName }
