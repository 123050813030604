import React, { useState } from 'react'
import feedbackButton from '../feedback-button.png';
import feedbackButtonHover from '../feedback-button-pink.png';

export default function Feedback(props) {
    const showSidebar = props.showSidebar;
    const setShowSidebar = props.setShowSidebar;
    const hideMainAnnotateButton = props.hideMainAnnotateButton;

    const [buttonImage, setButtonImage] = useState(feedbackButton);

    if (showSidebar) {
        return;
    }

    if(!hideMainAnnotateButton){
        return(
            <div className="annotate-widget__feedback-button">
                <img
                    src={buttonImage}
                    alt="Feedback by Annotate.ie"
                    onClick={() => setShowSidebar(true)}
                    onMouseEnter={() => setButtonImage(feedbackButtonHover)}
                    onMouseLeave={() => setButtonImage(feedbackButton)}
                />
            </div>
        )
    }
}
