import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('annotate-widget');
const root = createRoot(container);
console.log("Mounting annotate-widget");

export const init = (config) => {
  root.render(
    <React.StrictMode>
      <App
          projectId={config.projectId}
          hideMainAnnotateButton={config.hideMainAnnotateButton}
          integration={config.integration}
      />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
