import React, { useCallback, useEffect } from 'react'
// import uploadScreenshot from '../Firebase/Firebase';
import SidebarHeader from './SidebarHeader';
import annotateXIcon from '../Close-X.png';
import FormFooter from '../Form_Footer.png';
import SidebarModeSwitcher from './SidebarModeSwitcher';
import SidebarForm from './SidebarForm';

export default function Sidebar(props) {
    // Props
    const { showSidebar, setShowSidebar} = props;
    const { annotationMode, setAnnotationMode } = props;
    const { setThankYouMode } = props;
    const { showPindrop, setShowPindrop } = props;
    const { screenshot } = props;
    const { projectId } = props;
    const { integration } = props;

    // const [setUploaded] = useState(false);
    // const saveToFirebase = () => {
    //     uploadScreenshot(screenshot);
    //     setUploaded(true);
    // }

    const closeSidebar = () => {
        setShowSidebar(false);
        setAnnotationMode(null);
    }

    const startPinDrop = useCallback(() => {
        if (showPindrop) {
            setShowSidebar(false); 
            setShowPindrop(true);
        }
    }, [showPindrop, setShowPindrop, setShowSidebar]); 

    useEffect(() => {
        startPinDrop();
    }, [showPindrop, startPinDrop]);

    if (!showSidebar) {
        return;
    }

    return(
        <div className="annotate-widget__sidebar">
            <div className="annotate-widget__sidebar-header">
                <SidebarHeader />
                <img className="annotate-widget__sidebar-x" 
                    src={annotateXIcon} 
                    onClick={() => closeSidebar()} 
                    alt="Close sidebar" 
                />
            </div>

            <SidebarModeSwitcher 
                annotationMode={annotationMode} 
                setAnnotationMode={setAnnotationMode} 
                setShowPindrop={setShowPindrop}
            />

            <SidebarForm 
                screenshot={screenshot} 
                annotationMode={annotationMode} 
                setAnnotationMode={setAnnotationMode} 
                setThankYouMode={setThankYouMode}
                setShowSidebar={setShowSidebar}
                projectId={projectId}
                integration={integration}
            />
            
            <img className="annotate-widget__sidebar-footer" src={FormFooter} alt="Annotate.ie Logo" />

            {/* 
            {screenshot && <p>
                    <button 
                        onClick={saveToFirebase}
                    >⇧ Upload screenshot to Google Firebase</button>
                </p>
            }
            {uploaded && <p>👐 Screenshot uploaded.</p>} 
            */}
        </div>
    )
}
