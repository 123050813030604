export default function SidebarHeader() {
    // Exported from <https://www.freeformatter.com/xml-formatter.html#ad-output>
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="343.778" height="95.242" viewBox="0 0 343.778 95.242">
            <defs>
                <clipPath id="a">
                    <rect width="343.778" height="95.242" fill="#8a4184" stroke="#707070" strokeWidth="1" />
                </clipPath>
                <linearGradient id="b" x1="1.215" y1="-0.436" x2="0.038" y2="1.019" gradientUnits="objectBoundingBox">
                    <stop offset="0.132" stopColor="#c11e63" />
                    <stop offset="0.368" stopColor="#903d81" />
                    <stop offset="0.802" stopColor="#3a72b4" />
                    <stop offset="1" stopColor="#1887c9" />
                </linearGradient>
                <linearGradient id="c" x1="0.5" y1="-0.023" x2="0.5" y2="0.95" gradientUnits="objectBoundingBox">
                    <stop offset="0.1" stopColor="#9c9fa2" />
                    <stop offset="0.68" stopColor="#d3d4d7" />
                    <stop offset="1" stopColor="#ededef" />
                </linearGradient>
                <linearGradient id="d" x1="1.008" y1="-0.18" x2="0.064" y2="0.987" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#ced1d3" />
                    <stop offset="0.1" stopColor="#d4d6d8" />
                    <stop offset="0.59" stopColor="#ededef" />
                    <stop offset="0.62" stopColor="#e9eaec" />
                    <stop offset="0.85" stopColor="#d5d8da" />
                    <stop offset="1" stopColor="#ced1d3" />
                </linearGradient>
            </defs>
            <g transform="translate(-2040)">
                <g transform="translate(2040)" clipPath="url(#a)">
                    <path d="M179.843,655.953c-29.579,0-58.757-9.359-81.94-28.078-48.365-39.168-50.763-105.028-5.2-146.968C169.451,409.846,243.8,347.8,372.9,303.778S671.484,240,922.5,240h96.73c66.351,0,119.912,46.449,119.912,103.988s-53.561,103.988-119.912,103.988H922.5c-216.242,0-363.335,15.6-462.462,49.221C376.9,525.274,329.733,565.482,267.379,623.022,243.8,644.86,211.819,655.953,179.843,655.953Z" transform="translate(-495.739 -286.378)" fill="url(#b)" />
                </g>
                <g transform="translate(2070 27.12)">
                    <path d="M244.287,102A4.244,4.244,0,0,1,240,97.8V64.2a4.288,4.288,0,0,1,8.574,0V97.8a4.244,4.244,0,0,1-4.287,4.2Z" transform="translate(-214.279 -60)" fill="#ced1d3" />
                    <path d="M64.287,102a4.3,4.3,0,0,1-3.834-2.322,4.13,4.13,0,0,1,.4-4.4l25.721-33.6a4.346,4.346,0,0,1,6-.84,4.144,4.144,0,0,1,.857,5.88l-25.721,33.6A4.317,4.317,0,0,1,64.287,102Z" transform="translate(-60 -59.998)" fill="#ced1d3" />
                    <path d="M248.574,296.54v4.781a4.287,4.287,0,1,1-8.574,0V296.54Z" transform="translate(-214.279 -263.608)" fill="url(#c)" />
                    <path d="M64.292,257.147a4.287,4.287,0,0,1-3.124-7.222c2.746-2.924,5.4-5.5,10.01-7.3S81.868,240,90.836,240H94.3a4.287,4.287,0,0,1,0,8.574H90.836c-7.738,0-12.989.647-16.529,2.035-2.969,1.165-4.656,2.811-6.89,5.19A4.278,4.278,0,0,1,64.292,257.147Z" transform="translate(-60.005 -215.147)" fill="url(#d)" />
                </g>
                <g transform="translate(2130.874 35.119)">
                    <path d="M82.16,412.463a2.042,2.042,0,0,1-.613,1.518,1.947,1.947,0,0,1-1.361.592,1.865,1.865,0,0,1-1.2-.395,2.525,2.525,0,0,1-.8-1.069l-1.851-4.336H65.655l-1.858,4.37a2.246,2.246,0,0,1-.769,1.035A1.824,1.824,0,0,1,60,412.715a2.252,2.252,0,0,1,.109-.681l8.909-21.406a2.328,2.328,0,0,1,.864-1.109,1.892,1.892,0,0,1,1.286-.327,2.144,2.144,0,0,1,2,1.436l8.807,20.977A2.32,2.32,0,0,1,82.16,412.463ZM67.411,404.65h7.16l-3.621-8.372Zm38.19-14.735a1.994,1.994,0,0,1,.538,1.429v20.868a2.137,2.137,0,0,1-2.185,2.185,3.022,3.022,0,0,1-.98-.163,1.361,1.361,0,0,1-.681-.442L89.157,397.109v15.28a1.981,1.981,0,0,1-.551,1.436,1.94,1.94,0,0,1-1.45.572,1.838,1.838,0,0,1-1.4-.572,2.042,2.042,0,0,1-.531-1.436V391.521a2.151,2.151,0,0,1,2.178-2.185,2.77,2.77,0,0,1,1.062.218,1.749,1.749,0,0,1,.769.572l13.027,16.607V391.344a1.981,1.981,0,0,1,.551-1.429,1.906,1.906,0,0,1,1.416-.579A1.824,1.824,0,0,1,105.6,389.914Zm26.313,0a2.042,2.042,0,0,1,.538,1.429v20.868a2.137,2.137,0,0,1-2.185,2.185,3.089,3.089,0,0,1-.987-.163,1.361,1.361,0,0,1-.681-.442l-13.136-16.682v15.28a1.987,1.987,0,0,1-.558,1.436,1.919,1.919,0,0,1-1.45.572,1.824,1.824,0,0,1-1.4-.572,2,2,0,0,1-.538-1.436V391.521a2.151,2.151,0,0,1,2.185-2.185,2.763,2.763,0,0,1,1.055.218,1.708,1.708,0,0,1,.769.572l13.027,16.607V391.344a1.981,1.981,0,0,1,.558-1.429,1.892,1.892,0,0,1,1.416-.578,1.837,1.837,0,0,1,1.382.578Zm27.381,18.452a12.027,12.027,0,0,1-4.349,4.669,12.183,12.183,0,0,1-12.489,0,11.945,11.945,0,0,1-4.336-4.669,14.293,14.293,0,0,1,0-12.993,11.965,11.965,0,0,1,4.336-4.676,12.251,12.251,0,0,1,12.489,0,12.047,12.047,0,0,1,4.349,4.676A14.211,14.211,0,0,1,159.295,408.366Zm-4.009-10.849a7.834,7.834,0,0,0-2.722-3.076,7.167,7.167,0,0,0-7.746,0,7.534,7.534,0,0,0-2.654,3.1,10.55,10.55,0,0,0,0,8.732,7.576,7.576,0,0,0,2.654,3.1,7.187,7.187,0,0,0,7.746,0,7.786,7.786,0,0,0,2.722-3.151,10.271,10.271,0,0,0,0-8.7Z" transform="translate(-59.996 -388.986)" fill="#ededef" />
                    <path d="M230.977,389.743a.967.967,0,0,1,.3.749.919.919,0,0,1-.3.735,1.137,1.137,0,0,1-.769.265H222.8v21.943a1.014,1.014,0,0,1-.32.769,1.116,1.116,0,0,1-.79.306,1.069,1.069,0,0,1-.8-.306,1.028,1.028,0,0,1-.306-.769V391.492h-7.412a1.089,1.089,0,0,1-.769-.286.98.98,0,0,1-.306-.749.939.939,0,0,1,.306-.715,1.089,1.089,0,0,1,.769-.293h17.016A1.089,1.089,0,0,1,230.977,389.743Zm21.78,23.339-9.42-23.019a1.205,1.205,0,0,0-.415-.517.994.994,0,0,0-.626-.2,1.055,1.055,0,0,0-1.069.681l-9.454,23.141a1.293,1.293,0,0,0-.034.327v.116a1.028,1.028,0,0,0,2,.218l2.654-6.548h11.639l2.682,6.548a1.2,1.2,0,0,0,.415.517.994.994,0,0,0,.626.2,1.082,1.082,0,0,0,.749-.286.953.953,0,0,0,.327-.749,2.042,2.042,0,0,0-.075-.429Zm-15.545-7.916,4.935-12.251,5.016,12.251Zm34.95-15.423a.967.967,0,0,1,.306.749.919.919,0,0,1-.306.735,1.123,1.123,0,0,1-.769.265h-7.4v21.943a.987.987,0,0,1-.32.769,1.1,1.1,0,0,1-.789.306,1.082,1.082,0,0,1-.8-.306,1.028,1.028,0,0,1-.306-.769V391.492H254.37a1.062,1.062,0,0,1-.769-.286.98.98,0,0,1-.306-.749.939.939,0,0,1,.306-.715,1.068,1.068,0,0,1,.769-.293h17.016a1.082,1.082,0,0,1,.79.293Zm19.867,22.978a1.055,1.055,0,0,1,.306.749.987.987,0,0,1-.306.755,1.089,1.089,0,0,1-.769.286H278.124a1.048,1.048,0,0,1-1.075-1.075v-22.91a1.048,1.048,0,0,1,.32-.735,1.028,1.028,0,0,1,.769-.306h13.136a1.048,1.048,0,0,1,1.075,1.075.987.987,0,0,1-.306.755,1.089,1.089,0,0,1-.769.286H279.315v8.984H289.66a1.047,1.047,0,0,1,1.075,1.075.98.98,0,0,1-.306.749,1.075,1.075,0,0,1-.769.286H279.315v9.74h11.959a1.007,1.007,0,0,1,.769.286Z" transform="translate(-108.571 -389.101)" fill="#ced1d3" />
                </g>
            </g>
        </svg>
    )
}