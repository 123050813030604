import React from 'react'

export default function ThankYou(props) {
    const { thankYouMode, setThankYouMode } = props;

    if (!thankYouMode) {
        return;
    }

    const closeThankYou = () => {
        setThankYouMode(false);
    }

    return (
        <div className="annotate-widget__thank-you">
            <div className='annotate-widget__thank-you-logo'>
                <svg xmlns="http://www.w3.org/2000/svg" width="402" height="120" viewBox="0 0 402 120">
                    <g transform="translate(-6918 240)">
                        <rect width="402" height="120" transform="translate(6918 -240)" fill="none" />
                        <g transform="translate(-21.222 14)">
                            <path d="M99.583,430.919a3.647,3.647,0,0,1-1.094,2.711,3.477,3.477,0,0,1-2.431,1.058,3.331,3.331,0,0,1-2.14-.705,4.509,4.509,0,0,1-1.435-1.909l-3.307-7.744H70.1l-3.319,7.8a4.011,4.011,0,0,1-1.374,1.848A3.258,3.258,0,0,1,60,431.369a4.023,4.023,0,0,1,.194-1.216l15.913-38.233a4.158,4.158,0,0,1,1.544-1.982,3.38,3.38,0,0,1,2.3-.583,3.829,3.829,0,0,1,3.574,2.565l15.731,37.467A4.144,4.144,0,0,1,99.583,430.919ZM73.239,416.963H86.028l-6.467-14.953Zm68.211-26.319a3.562,3.562,0,0,1,.96,2.553v37.272a3.817,3.817,0,0,1-3.9,3.9,5.4,5.4,0,0,1-1.751-.292,2.431,2.431,0,0,1-1.216-.79l-23.462-29.8v27.292a3.538,3.538,0,0,1-.985,2.565,3.465,3.465,0,0,1-2.589,1.021,3.283,3.283,0,0,1-2.5-1.021,3.647,3.647,0,0,1-.948-2.565V393.513a3.841,3.841,0,0,1,3.89-3.9,4.947,4.947,0,0,1,1.9.389,3.123,3.123,0,0,1,1.374,1.021l23.268,29.662V393.2a3.538,3.538,0,0,1,.985-2.553,3.4,3.4,0,0,1,2.529-1.033A3.258,3.258,0,0,1,141.451,390.644Zm47,0a3.647,3.647,0,0,1,.96,2.553v37.272a3.817,3.817,0,0,1-3.9,3.9,5.516,5.516,0,0,1-1.763-.292,2.431,2.431,0,0,1-1.216-.79l-23.462-29.8v27.292a3.55,3.55,0,0,1-1,2.565,3.428,3.428,0,0,1-2.589,1.021,3.258,3.258,0,0,1-2.492-1.021,3.574,3.574,0,0,1-.96-2.565V393.513a3.841,3.841,0,0,1,3.9-3.9,4.934,4.934,0,0,1,1.884.389,3.051,3.051,0,0,1,1.374,1.021l23.268,29.662V393.2a3.538,3.538,0,0,1,1-2.553,3.38,3.38,0,0,1,2.529-1.033,3.282,3.282,0,0,1,2.468,1.033ZM237.355,423.6a21.48,21.48,0,0,1-7.768,8.339,21.76,21.76,0,0,1-22.308,0,21.334,21.334,0,0,1-7.744-8.339,25.529,25.529,0,0,1,0-23.207,21.372,21.372,0,0,1,7.744-8.352,21.882,21.882,0,0,1,22.307,0,21.518,21.518,0,0,1,7.768,8.352A25.383,25.383,0,0,1,237.355,423.6Zm-7.16-19.378a13.992,13.992,0,0,0-4.863-5.495,12.8,12.8,0,0,0-13.834,0,13.458,13.458,0,0,0-4.741,5.531,18.843,18.843,0,0,0,0,15.6,13.531,13.531,0,0,0,4.741,5.531,12.837,12.837,0,0,0,13.834,0,13.907,13.907,0,0,0,4.863-5.629,18.344,18.344,0,0,0,0-15.536Z" transform="translate(6916.226 -605.986)" fill="#545554" />
                            <path d="M245.825,390.056a1.726,1.726,0,0,1,.535,1.337,1.641,1.641,0,0,1-.535,1.313,2.03,2.03,0,0,1-1.374.474H231.213v39.193a1.811,1.811,0,0,1-.571,1.374,1.993,1.993,0,0,1-1.41.547,1.908,1.908,0,0,1-1.435-.547,1.836,1.836,0,0,1-.547-1.374V393.18H214.011a1.944,1.944,0,0,1-1.374-.511,1.751,1.751,0,0,1-.547-1.337,1.678,1.678,0,0,1,.547-1.276,1.945,1.945,0,0,1,1.374-.523H244.4A1.946,1.946,0,0,1,245.825,390.056Zm38.9,41.685L267.9,390.628a2.152,2.152,0,0,0-.742-.924,1.775,1.775,0,0,0-1.118-.353,1.884,1.884,0,0,0-1.909,1.216L247.247,431.9a2.309,2.309,0,0,0-.061.584v.207a1.836,1.836,0,0,0,3.574.389l4.741-11.695H276.29l4.79,11.695a2.151,2.151,0,0,0,.742.924,1.775,1.775,0,0,0,1.118.353,1.933,1.933,0,0,0,1.337-.511,1.7,1.7,0,0,0,.584-1.337,3.646,3.646,0,0,0-.134-.766ZM256.961,417.6l8.814-21.882,8.959,21.882Zm62.425-27.547a1.726,1.726,0,0,1,.547,1.337,1.641,1.641,0,0,1-.547,1.313,2.006,2.006,0,0,1-1.374.474H304.8v39.193a1.763,1.763,0,0,1-.571,1.374,1.958,1.958,0,0,1-1.41.547,1.933,1.933,0,0,1-1.435-.547,1.835,1.835,0,0,1-.547-1.374V393.18H287.607a1.9,1.9,0,0,1-1.374-.511,1.75,1.75,0,0,1-.547-1.337,1.678,1.678,0,0,1,.547-1.276,1.909,1.909,0,0,1,1.374-.523H318a1.933,1.933,0,0,1,1.41.523ZM354.87,431.1a1.884,1.884,0,0,1,.547,1.337,1.763,1.763,0,0,1-.547,1.349,1.946,1.946,0,0,1-1.374.511H330.034a1.872,1.872,0,0,1-1.921-1.921V391.454a1.872,1.872,0,0,1,.571-1.313,1.836,1.836,0,0,1,1.374-.547h23.462a1.872,1.872,0,0,1,1.921,1.921,1.763,1.763,0,0,1-.547,1.349,1.946,1.946,0,0,1-1.374.511H332.162v16.047H350.64a1.87,1.87,0,0,1,1.921,1.921,1.75,1.75,0,0,1-.547,1.337,1.92,1.92,0,0,1-1.374.511H332.162v17.4h21.359a1.8,1.8,0,0,1,1.374.511Z" transform="translate(6949.027 -605.909)" fill="#9c9fa2" />
                        </g>
                    </g>
                </svg>
            </div>
            
            <h1>Thank You For Your Feedback</h1>
            <p>We will attend to this as soon as possible.</p>
            <button onClick={closeThankYou}>Close</button>

            <div className='annotate-widget__thank-you-watermark'>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="814" height="1080" viewBox="0 0 814 1080">
                    <defs>
                        <clipPath id="a">
                            <rect width="814" height="1080" transform="translate(1106)" fill="#fff" stroke="#707070" strokeWidth="1" />
                        </clipPath>
                        <linearGradient id="b" x1="0.5" y1="-0.023" x2="0.5" y2="0.95" gradientUnits="objectBoundingBox">
                            <stop offset="0.096" stopColor="#9c9fa2" />
                            <stop offset="0.68" stopColor="#d3d4d7" />
                            <stop offset="1" stopColor="#ededef" />
                        </linearGradient>
                        <linearGradient id="c" x1="1.215" y1="-0.436" x2="0.038" y2="1.019" gradientUnits="objectBoundingBox">
                            <stop offset="0.132" stopColor="#c11e63" />
                            <stop offset="0.368" stopColor="#903d81" />
                            <stop offset="0.802" stopColor="#3a72b4" />
                            <stop offset="1" stopColor="#1887c9" />
                        </linearGradient>
                    </defs>
                    <g transform="translate(-1106)" clipPath="url(#a)">
                        <g transform="translate(971.278 -189)" opacity="0.603">
                            <g transform="translate(968.688 60)">
                                <path d="M391.45,1574.5A151.247,151.247,0,0,1,240,1423.047V211.45a151.45,151.45,0,0,1,302.9,0v1211.6A151.247,151.247,0,0,1,391.45,1574.5Z" transform="translate(-240 -60)" fill="#ced1d3" />
                            </g>
                            <g transform="translate(60.003 60.013)">
                                <path d="M211.439,1574.486a152.328,152.328,0,0,1-90.87-30.29c-67.143-49.978-80.268-144.887-30.29-212.03l908.7-1211.6c49.978-67.143,144.887-80.268,212.03-30.29s80.268,144.887,30.29,212.03l-908.7,1211.6A150.588,150.588,0,0,1,211.439,1574.486Z" transform="translate(-60.003 -60.003)" fill="#ced1d3" />
                            </g>
                            <path d="M542.9,296.5V465.619a151.45,151.45,0,0,1-302.9,0V296.5Z" transform="translate(728.688 957.428)" fill="url(#b)" />
                            <g transform="translate(60.106 968.698)">
                                <path d="M211.356,845.8a151.007,151.007,0,0,1-103.491-40.891C46.781,747.861,43.752,651.943,101.3,590.858,198.23,487.368,292.129,397,455.19,332.889S832.3,240,1149.334,240H1271.5a151.45,151.45,0,0,1,0,302.9H1149.334c-273.114,0-458.893,22.717-584.091,71.686C460.238,655.477,400.668,714.038,321.914,797.84A151.175,151.175,0,0,1,211.356,845.8Z" transform="translate(-60.023 -240)" fill="url(#c)" />
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    )
}