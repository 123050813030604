import React, { useState } from 'react'
import annotatePin from '../pin-icon.png';

export default function Pindrop(props) {
    const { showPindrop, setShowPindrop, setShowSidebar } = props;
    const { takeScreenshot } = props;

    // TODO Using `undefined` as a value
    const invisibleCoordinate = 100000;
    
    const [coordinateX, setCoordinateX] = useState(invisibleCoordinate);
    const [coordinateY, setCoordinateY] = useState(invisibleCoordinate);
    const annotatePinIconBase64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAfCAYAAAAIjIbwAAAABHNCSVQICAgIfAhkiAAABB1JREFUSEudVl1sFFUUvucOrY/MIy8k0+gqIQKz8oKJpttEjWBMd4WSEKPdhpC0YOgsgqRNTWdDAEOJHVSgQeLuhgBJbdw2hBTjQ2d5wehDV32QFGOmJiY+Lo+2nTmee2dndvaPRe4+3LnnnvPdc879zrkLrM24GTulwyYcZIg6A6YzZCrNFZrLwND2XGXh/UdT5Vbm0CgsbjM0dwNzjEGCEUJtCNWGNWJe6YJs6qHlRHHqQO9oHyXJLEdCFSVAAEQz0hr8NdAvsl+hdepd5ys7AA5Bv986kkTEYrt0dJJz5PG3/r4i0yFBF7cMa1zxlgG4SsChg0CeiTXNJUSP5Fwlwa4ggGDfX2PFc3l87z8zjgS9u3XEJoVePyR/yBCRLawp3emUY1UC+aI2rJGxRef1N+oTcmHfX1fTUNSO6grw5apHgWdiLvQ7l9Ptwl7QjuXJEcEOmevA3sWNHvju+eMWGY5K4/BucPW9P7/UhOjbF4wEApsmQ6IVipwNHXxklYuaoSJ3HZJvDg+WmcMMzMYMm3JAoVdDFrcLLDuwYplCeTaWqZBmxBDLBBoXe3OxjOUxHK1xRLKiBLdf+pjyhZslXYJLYrzv0MOLtgR9MRMlp3Tq4Mq0vIvb206YhDLpX0JoX4Ib209SdLWcyOQjT33w+4X5lp4ytkqgMjU3tn9C0XiTjfaQf/l0vSfiQLrFod8upH1Qg2awZAqQPQbE5MAflowit+P0PCDrD3NKHwT2C3y9c4zoxHplBEIoLxMqHLz4UPkzJ2oQ/b6mj+sKIrGmalidPGQlmHllwiKkSLJDEpS7satvqGyGHA1Ac7qprsH6EuHpkUL2yQOQhSu7J5IIvBjwrI53DBxSNNddvpAh8Kt7TM3b2BhkjBukr4qKauQpVV0cpulUpRvJOMK3djF3kNN1Pz7+U1aV1Pj81TN5OnnQP1jWetiU/t8aLp148KkhQadeO5NAhKVndDA0c12lZ+zHMb+hiHH29XPVplLXRSNdNeijrfepsgoT98eJftXWJz4m3phKEImf2Vtlk9Jj3stICtZ1/lP7vrAph711Hb6x47dac1a4eHdUetkEejR5mXjHl4ONDi9U2H1dxnpm5kekl02gQnB44LooydHAoY4zw+w3s0fM6CU3vabpdE5dW+fUJ2Wth4+dNGpaw+qtmx9qUcCWngrh/sO3kp7HipE3KvoiVHmMTFGgb+76IfupQIXS28NzYQeqc7BW7JcWZw4YjYBtPRUbCaOogstF7UcauF/rRL1VVFzdtlJNzeaJoGJzz8nFBOWRulHtj4VoyC734j9PvdPyL09HUKGwc/wHExAmgxQQjzO/nn9TPJZtR9Ptt9KMmSWbOjwVBSusmL3pJwE+ladCSTOXVLfrubyy/m/aMfta5jF60H8iyvrJtfuskgAAAABJRU5ErkJggg==';
    
    const droppedPinStyles = {
        left: coordinateX,
        top: coordinateY,
        position: 'absolute'
    };

    function recordMousePosition(event) {
        setCoordinateX(event.clientX);
        setCoordinateY(window.pageYOffset + event.clientY);
    }

    // TODO Consider using custom hook to extract functionality
    // <https://www.freecodecamp.org/news/best-practices-for-react/>
    React.useEffect(() => {
        if (showPindrop) {
            window.addEventListener("mousedown", recordMousePosition);
            // Annotate's Pin icon
            document.body.style.cursor = `url(${annotatePinIconBase64}), auto`;
        }
        return () => {
            window.removeEventListener("mousedown", recordMousePosition);
            document.body.style.cursor = 'auto';
        };
    }, [showPindrop]);

    React.useEffect(() => {
        // Avoid taking screenshot on initial render
        if (coordinateX === invisibleCoordinate) {
            return;
        }
        if (coordinateY === invisibleCoordinate) {
            return;
        }

        const getImage = () => {
            // Useful comment, to ensure that the screenshot isn't being taken by mistake
            console.log(`Taking screenshot with pin at ${coordinateX},${coordinateY}`);
            
            takeScreenshot(document.body);
    
            // Reset coordinates
            setCoordinateX(invisibleCoordinate);
            setCoordinateY(invisibleCoordinate);

            // Job done, get back to sidebar
            setShowPindrop(false);
            setShowSidebar(true);
        }
        
        getImage();
    }, [
            coordinateX, 
            setCoordinateX, 
            coordinateY, 
            setCoordinateY, 
            takeScreenshot, 
            setShowPindrop, 
            setShowSidebar, 
            invisibleCoordinate
        ]
    );
    
    if (!showPindrop) {
        // console.log("Not showing pindrop");
        return;
    }

    return(
        <div className="annotate-widget__pin-drop">
            <img className="annotate-widget__dropped-pin" src={annotatePin} style={droppedPinStyles} alt="Dropped pin" />
        </div>        
    )
}