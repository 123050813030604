import React from 'react';
import * as ANNOTATION from '../constants/annotation';


export default function Screenshot(props) {
    const { screenshot, showSidebar, pinDropMode, annotationMode } = props;

    if (!screenshot) {
        return;
    }
    if (!showSidebar) {
        return;
    }
    if (pinDropMode) {
        return;
    }
    if (!annotationMode) {
        return;
    }
    if (annotationMode === ANNOTATION.MODE.PINDROP) {
        return;
    }

    return (
        <div>    
            {screenshot && <div className='annotate-widget__screenshot'>
                <img src={screenshot} alt="Screenshot" />
            </div>}
        </div>
    )
}