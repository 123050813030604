import React, { useEffect, useRef, useState, useCallback } from 'react'
import { browserName, deviceType, fullBrowserVersion, osName, osVersion } from 'react-device-detect';
import * as ANNOTATION from '../constants/annotation';

import createIssue from '../utils/Jira';

export default function SidebarForm(props) {
    const { annotationMode, setAnnotationMode, setShowSidebar, setThankYouMode, screenshot, projectId, integration } = props;

    const titleField = useRef(null);
    const typeField = useRef(null);
    const descriptionField = useRef(null);

    const [ typeOptionsHtml, setTypeOptionsHtml ] = useState([]);

    const setOptions = useCallback((mode) => {
        if (mode === ANNOTATION.MODE.IMAGE) {
            setTypeOptionsHtml([
                <option key="placeholder" label=" "></option>,
                <option key="replace">Replace Image</option>,
                <option key="edit">Edit Image</option>,
                <option key="crop">Crop Image</option>,
                <option key="remove">Remove Image</option>,
                <option key="other">Other</option>,
            ]);
        }

        if (mode === ANNOTATION.MODE.TEXT) {
            setTypeOptionsHtml([
                <option key="placeholder" label=" "></option>,
                <option key="replace">Replace Text</option>,
                <option key="edit">Edit Text</option>,
                <option key="remove">Remove Text</option>,
                <option key="other">Other</option>,
            ]);
        }

        if (mode === ANNOTATION.MODE.FUNCTION) {
            setTypeOptionsHtml([
                <option key="placeholder" label=" "></option>,
                <option key="replace">Replace Functionality</option>,
                <option key="edit">Edit Functionality</option>,
                <option key="remove">Remove Functionality</option>,
                <option key="other">Other</option>,
            ]);
        }

        if (mode === ANNOTATION.MODE.GENERAL) {
            setTypeOptionsHtml([
                <option key="placeholder" label=" "></option>,
                <option key="general">General</option>,
            ]);
        }
    }, [setTypeOptionsHtml]); 

    useEffect( () => {
        setOptions(annotationMode);
    }, [annotationMode, setOptions]);
    
    const showThankyou = () => {
        setAnnotationMode(false);
        setShowSidebar(false);
        setThankYouMode(true);
    };

    const createJiraIssue = (event) => {
        event.preventDefault();

        const browserInfo = {
            browserName: browserName,
            fullBrowserVersion: fullBrowserVersion,
            osName: osName,
            osVersion: osVersion,
            deviceType: deviceType,
            currentUrl: window.location.href
        };
        try {
            createIssue(
                showThankyou,
                integration,
                projectId,
                screenshot, 
                titleField.current.value,
                typeField.current.value,
                descriptionField.current.value,
                JSON.stringify(browserInfo)
            );
        } catch (error) {
            console.error("Failed to create Jira issue:");
            console.log(error);
        }
    }

    return (
        <form onSubmit={createJiraIssue}>
            <fieldset disabled={!annotationMode}>
                <label htmlFor="title">Title *</label>
                <input id="title" type="text" ref={titleField} placeholder="Insert your title here" required />

                <label htmlFor="type">Type</label>
                <select id="type" ref={typeField}>
                    {typeOptionsHtml}
                </select>

                <label htmlFor="description">Description</label>
                <textarea id="description" ref={descriptionField}></textarea>

                <input type="submit" value="Submit" onClick={createJiraIssue}/>
            </fieldset>
        </form>
    )
}