import * as ANNOTATION from "../constants/annotation";
import comFunction from "../Function-Icon-Pink.png";
import comFunctionGrey from "../Function-Icon-Grey.png";
import comGeneral from "../General-Icon-Pink.png";
import comGeneralGrey from "../General-Icon-Grey.png";
import comText from "../Text-Icon-Pink.png";
import comTextGrey from "../Text-Icon-Grey.png";
import comImage from "../Image-Icon-Pink.png";
import comImageGrey from "../Image-Icon-Grey.png";
import { useState } from "react";

export default function SidebarModeSwitcher(props) {
  const { annotationMode, setAnnotationMode, setShowPindrop } = props;

  const [showModeHover, setShowModeHover] = useState(ANNOTATION.MODE.NONE);

  const setMode = (mode) => {
    setAnnotationMode(mode);
  };

  const showPinDropForMode = (mode) => {
    setAnnotationMode(mode);
    setShowPindrop(true);
  };

  return (
    <div className="annotate-widget__sidebar-modes">
      <>
        <div className="annotate-widget__sidebar-direction">
          SELECT AN ICON TO TAKE A SCREENSHOT
        </div>
        <table>
          <tbody>
            <tr>
              <td>
                {!annotationMode && (
                  <img
                    className="selectable"
                    src={comImage}
                    alt="Annotate a graphic"
                    onClick={() => showPinDropForMode(ANNOTATION.MODE.IMAGE)}
                    onMouseEnter={() => setShowModeHover(ANNOTATION.MODE.IMAGE)}
                    onMouseOut={() => setShowModeHover(ANNOTATION.MODE.NONE)}
                  />
                )}
                {annotationMode === ANNOTATION.MODE.IMAGE && (
                  <img
                    src={annotationMode === ANNOTATION.MODE.IMAGE ? comImage : comImageGrey}
                    alt="Annotate a graphic"
                    className="selectable"
                    onMouseEnter={() => setShowModeHover(ANNOTATION.MODE.IMAGE)}
                    onMouseOut={() => setShowModeHover(ANNOTATION.MODE.NONE)}
                  />
                )}
                {annotationMode && annotationMode !== ANNOTATION.MODE.IMAGE && (
                  <img
                    src={comImageGrey}
                    alt="Annotate a graphic"
                    onClick={() => setMode(ANNOTATION.MODE.IMAGE)}
                    className="selectable"
                    onMouseEnter={() => setShowModeHover(ANNOTATION.MODE.IMAGE)}
                    onMouseOut={() => setShowModeHover(ANNOTATION.MODE.NONE)}
                  />
                )}
              </td>

              <td>
                {!annotationMode && (
                  <img
                    className="selectable"
                    src={comText}
                    alt="Annotate text"
                    onClick={() => {
                      showPinDropForMode(ANNOTATION.MODE.TEXT);
                      setShowModeHover(ANNOTATION.MODE.NONE);
                    }}
                    onMouseEnter={() => setShowModeHover(ANNOTATION.MODE.TEXT)}
                    onMouseOut={() => setShowModeHover(ANNOTATION.MODE.NONE)}
                  />
                )}
                {annotationMode === ANNOTATION.MODE.TEXT && (
                  <img
                    src={comText}
                    alt="Annotate text"
                    className="selectable"
                    onMouseEnter={() => setShowModeHover(ANNOTATION.MODE.TEXT)}
                    onMouseOut={() => setShowModeHover(ANNOTATION.MODE.NONE)}
                  />
                )}
                {annotationMode && annotationMode !== ANNOTATION.MODE.TEXT && (
                  <img
                    src={comTextGrey}
                    alt="Annotate text"
                    className="selectable"
                    onClick={() => setMode(ANNOTATION.MODE.TEXT)}
                    onMouseEnter={() => setShowModeHover(ANNOTATION.MODE.TEXT)}
                    onMouseOut={() => setShowModeHover(ANNOTATION.MODE.NONE)}
                  />
                )}
              </td>

              <td>
                {!annotationMode && (
                  <img
                    className="selectable"
                    src={comFunction}
                    alt="Annotate functionality"
                    onClick={() => showPinDropForMode(ANNOTATION.MODE.FUNCTION)}
                    onMouseEnter={() => setShowModeHover(ANNOTATION.MODE.FUNCTION)}
                    onMouseOut={() => setShowModeHover(ANNOTATION.MODE.NONE)}
                  />
                )}
                {annotationMode === ANNOTATION.MODE.FUNCTION && (
                  <img
                    src={comFunction}
                    alt="Annotate functionality"
                    className="selectable"
                    onMouseEnter={() => setShowModeHover(ANNOTATION.MODE.FUNCTION)}
                    onMouseOut={() => setShowModeHover(ANNOTATION.MODE.NONE)}
                  />
                )}
                {annotationMode && annotationMode !== ANNOTATION.MODE.FUNCTION && (
                  <img
                    src={comFunctionGrey}
                    alt="Annotate functionality"
                    className="selectable"
                    onClick={() => setMode(ANNOTATION.MODE.FUNCTION)}
                    onMouseEnter={() => setShowModeHover(ANNOTATION.MODE.FUNCTION)}
                    onMouseOut={() => setShowModeHover(ANNOTATION.MODE.NONE)}
                  />
                )}
              </td>

              <td>
                {!annotationMode && (
                  <img
                    className="selectable"
                    src={comGeneral}
                    alt="Annogate general"
                    onClick={() => showPinDropForMode(ANNOTATION.MODE.GENERAL)}
                    onMouseEnter={() => setShowModeHover(ANNOTATION.MODE.GENERAL)}
                    onMouseOut={() => setShowModeHover(ANNOTATION.MODE.NONE)}
                  />
                )}
                {annotationMode === ANNOTATION.MODE.GENERAL && (
                  <img
                    src={comGeneral}
                    alt="Annotate general"
                    className="selectable"
                    onMouseEnter={() => setShowModeHover(ANNOTATION.MODE.GENERAL)}
                    onMouseOut={() => setShowModeHover(ANNOTATION.MODE.NONE)}
                  />
                )}
                {annotationMode && annotationMode !== ANNOTATION.MODE.GENERAL && (
                  <img
                    src={comGeneralGrey}
                    alt="General annotation"
                    className="selectable"
                    onClick={() => setMode(ANNOTATION.MODE.GENERAL)}
                    onMouseEnter={() => setShowModeHover(ANNOTATION.MODE.GENERAL)}
                    onMouseOut={() => setShowModeHover(ANNOTATION.MODE.NONE)}
                  />
                )}
              </td>
            </tr>
            <tr>
              <td>
                <span className={showModeHover === ANNOTATION.MODE.IMAGE ? "shown" : ""}>
                  Image
                </span>
              </td>
              <td>
                <span className={showModeHover === ANNOTATION.MODE.TEXT ? "shown" : ""}>Text</span>
              </td>
              <td>
                <span className={showModeHover === ANNOTATION.MODE.FUNCTION ? "shown" : ""}>
                  Functionality
                </span>
              </td>
              <td>
                <span className={showModeHover === ANNOTATION.MODE.GENERAL ? "shown" : ""}>
                  General
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    </div>
  );
}
