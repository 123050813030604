import './App.css';
import React, { useState, useEffect } from 'react'
import { useScreenshot } from './utils/ScreenCapture';
import Sidebar from './components/Sidebar';
import Feedback from './components/Feedback';
import PinDrop from './components/Pindrop';
import ThankYou from './components/ThankYou';
import Screenshot from './components/Screenshot';

function App(props) {
  const { integration, projectId, hideMainAnnotateButton } = props;
  // console.log("In the app, we got projectId: " + projectId);

  const forceSidebarOnForDevelopment = false;
  const forceThankYouForDevelopment = false;

  const [showSidebar, setShowSidebar] = useState(forceSidebarOnForDevelopment);
  const [showPindrop, setShowPindrop] = useState(false);
  const [thankYouMode, setThankYouMode] = useState(forceThankYouForDevelopment);
  
  const [screenshot, takeScreenshot] = useScreenshot({
    type: "image/png"
  });

  const [annotationMode, setAnnotationMode] = useState(false);

  // Extra button
  const extraAnnotateButton = document.getElementById('extra-annotate-button');
  if(extraAnnotateButton){
      extraAnnotateButton.addEventListener('click', function(e){
          e.preventDefault();
          setShowSidebar(true);
      });
  }

  // Load Montserrat font
  useEffect(() => {
    var head = document.head;
    var link = document.createElement("link");

    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap";

    head.appendChild(link);

    return () => { head.removeChild(link); }

  }, []);

  return (
    <div className="annotate-widget">
      <div className='annotate-widget__feedback-holder'>
        <Feedback 
          showSidebar={showSidebar} 
          setShowSidebar={setShowSidebar}
          hideMainAnnotateButton={hideMainAnnotateButton}
        />
      </div>

      <div className='annotate-widget__screenshot-sidebar-holder'>
        <Screenshot
          showSidebar={showSidebar}
          screenshot={screenshot}
          showPindrop={showPindrop}
          annotationMode={annotationMode}
        />
        <Sidebar 
          showSidebar={showSidebar} 
          setShowSidebar={setShowSidebar} 
          thankYouMode={thankYouMode}
          setThankYouMode={setThankYouMode}
          showPindrop={showPindrop}
          setShowPindrop={setShowPindrop} 
          annotationMode={annotationMode}
          setAnnotationMode={setAnnotationMode}
          screenshot={screenshot}
          projectId={projectId}
          integration={integration}
        />
      </div>

      <PinDrop 
        setShowSidebar={setShowSidebar}
        showPindrop={showPindrop}
        setShowPindrop={setShowPindrop} 
        takeScreenshot={takeScreenshot}
        setAnnotationMode={setAnnotationMode}
      />
      <ThankYou
        thankYouMode={thankYouMode}
        setThankYouMode={setThankYouMode}
      />
    </div>
  );
}

export default App;
