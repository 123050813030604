const issueCreated = (showThankyou) => {
  showThankyou();
};

const createWithBlob = (integration, projectId, myBlob, title, imageType, description, meta, showThankyou) => {
  const form = new FormData();
  form.append("projectId", projectId);
  form.append("title", title);
  form.append("imageType", imageType);
  form.append("description", description);
  form.append("meta", meta);
  form.append("screenshot", myBlob);
  form.append("integration", integration);
  const issuesPath = `${process.env.REACT_APP_WIDGET_BACKEND_URL}/annotations/${projectId}/`;

  console.log(issuesPath);
  console.log(form.get('screenshot'));

  fetch(issuesPath, {
    method: "POST",
    body: form,
    enctype: "multipart/form-data",
  })
    .then((response) => {
      console.log(response);
      if (response.status !== 200) {
        throw new Error(`There was an error with status code ${response.status}`);
      }
      return response.json();
    })
    .then((json) => {
      console.log("Request to create issue");
      console.log(json);

      issueCreated(showThankyou);
    })
    .catch((error) => {
      // Network error
      console.error("There was an error!", error);

      return false;
    });
};

const createIssue = (showThankyou, integration, projectId, screenshot, title, imageType, description, meta) => {
  if (!projectId) {
    console.error("Empty projectId!");
    return;
  }
  if (!screenshot) {
    console.error("Empty screenshot!");
    return;
  }

  let base64EncodedImageString = screenshot.replace("data:image/png;base64,", "");

  // Convert base64 string to binary (using fetch) and then upload it.
  fetch(`data:image/png;base64,${base64EncodedImageString}`)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`There was an error with status code ${response.status}`);
      }

      response
        .blob()
        .then((myBlob) => {
          try {
            createWithBlob(integration, projectId, myBlob, title, imageType, description, meta, showThankyou);
          } catch (error) {
            throw Error(error);
          }
        })
        .catch((error) => {
          throw Error(error);
        });
    })
    .catch((error) => {
      // Network error
      throw Error(error);
    });
};

export default createIssue;
